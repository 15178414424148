export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Clientes',
    icon: 'UserIcon',
    children: [
      {
        title: 'Adicionar',
        route: 'adicionar-cliente',
      },
      {
        title: 'Editar',
        route: 'editar-cliente',
      },
      {
        title: 'Listagem',
        route: 'listar-cliente',
      },
    ],
  },
  {
    title: 'Contratos',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Adicionar',
        route: 'adicionar-contrato',
      },
      {
        title: 'Editar',
        route: 'editar-contrato',
      },
      {
        title: 'Listagem',
        route: 'listar-contrato',
      },
    ],
  },
  {
    title: 'Processos',
    icon: 'LayoutIcon',
    children: [
      {
        title: 'Tickets',
        route: 'editar-processo',
      },
      {
        title: 'Listagem',
        route: 'listar-processo',
      },
      {
        title: 'Pendentes',
        route: 'listar-processo-pendente',
      },
      {
        title: 'Faturação',
        route: 'listar-processo-faturacao',
      },
    ],
  },
  {
    title: 'Tabelas',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Documentos de Contrato',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-documentosContrato',
          },
          {
            title: 'Editar',
            route: 'editar-documentosContrato',
          },
          {
            title: 'Listagem',
            route: 'listar-documentosContrato',
          },
        ],
      },
      {
        title: 'Documentos de Processo',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-documentosProcesso',
          },
          {
            title: 'Editar',
            route: 'editar-documentosProcesso',
          },
          {
            title: 'Listagem',
            route: 'listar-documentosProcesso',
          },
        ],
      },
      {
        title: 'Estados',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-estado',
          },
          {
            title: 'Editar',
            route: 'editar-estado',
          },
          {
            title: 'Listagem',
            route: 'listar-estado',
          },
        ],
      },
      {
        title: 'Moedas',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-moeda',
          },
          {
            title: 'Editar',
            route: 'editar-moeda',
          },
          {
            title: 'Listagem',
            route: 'listar-moeda',
          },
        ],
      },
      {
        title: 'Motivos',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-motivo',
          },
          {
            title: 'Editar',
            route: 'editar-motivo',
          },
          {
            title: 'Listagem',
            route: 'listar-motivo',
          },
        ],
      },
      {
        title: 'Países',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-pais',
          },
          {
            title: 'Editar',
            route: 'editar-pais',
          },
          {
            title: 'Listagem',
            route: 'listar-pais',
          },
        ],
      },
      {
        title: 'Perfis',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-perfil',
          },
          {
            title: 'Editar',
            route: 'editar-perfil',
          },
          {
            title: 'Listagem',
            route: 'listar-perfil',
          },
        ],
      },
      {
        title: 'Prioridades',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-prioridade',
          },
          {
            title: 'Editar',
            route: 'editar-prioridade',
          },
          {
            title: 'Listagem',
            route: 'listar-prioridade',
          },
        ],
      },
      {
        title: 'Técnicos',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-tecnico',
          },
          {
            title: 'Editar',
            route: 'editar-tecnico',
          },
          {
            title: 'Listagem',
            route: 'listar-tecnico',
          },
        ],
      },
      {
        title: 'Tipos de Intervenção',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-tiposIntervencao',
          },
          {
            title: 'Editar',
            route: 'editar-tiposIntervencao',
          },
          {
            title: 'Listagem',
            route: 'listar-tiposIntervencao',
          },
        ],
      },
      {
        title: 'Tipos de Processo',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-tiposProcesso',
          },
          {
            title: 'Editar',
            route: 'editar-tiposProcesso',
          },
          {
            title: 'Listagem',
            route: 'listar-tiposProcesso',
          },
        ],
      },
      {
        title: 'Utilizadores',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-utilizador',
          },
          {
            title: 'Editar',
            route: 'editar-utilizador',
          },
        ],
      },
      {
        title: 'Vendedores',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-vendedor',
          },
          {
            title: 'Editar',
            route: 'editar-vendedor',
          },
          {
            title: 'Listagem',
            route: 'listar-vendedor',
          },
        ],
      },
      {
        title: 'Zonas',
        children: [
          {
            title: 'Adicionar',
            route: 'adicionar-zona',
          },
          {
            title: 'Editar',
            route: 'editar-zona',
          },
          {
            title: 'Listagem',
            route: 'listar-zona',
          },
        ],
      },
    ],
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    route: 'configuracao',

  },
]
